import { useIntl } from 'react-intl';
import { ErrorLayout } from '@/components/ErrorLayout/ErrorLayout';
import { RestaurantInMagIllustration } from '@/components/NoAvailabilities/RestaurantInMag.illustration';
import * as S from './NotFoundError.styles';

export type NotFoundErrorProps = Record<string, never>;

export const NotFoundError: React.FC<NotFoundErrorProps> = () => {
  const intl = useIntl();
  const title = intl.formatMessage({
    id: 'tf_widget_notFoundError_title',
    defaultMessage: 'Page not found.',
  });
  const message = intl.formatMessage({
    id: 'tf_widget_notFoundError_message',
    defaultMessage: 'Page does not exist.',
  });
  return (
    <S.Container>
      <ErrorLayout Illustration={RestaurantInMagIllustration} title={title} message={message} />
    </S.Container>
  );
};
